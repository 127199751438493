<template>
  <div class="servicer-box">
    <ul>
      <li
        class="img-box"
        v-for="(item, index) in imgs"
        :key="index"
        @click="newsIn(index)"
      >
        <img class="img" :src="item.img" alt="" />
        <div class="text-box">
          <div class="text">{{ item.title }}</div>
          <div class="text2">{{ item.date }}</div>
        </div>
      </li>
    </ul>
    <!-- <div class="loading" v-if="loading">
      <span id="load-text">{{ loadText }}</span>
    </div> -->
    <div class="more" @click="more()" v-if="!loading">加载更多...</div>
  </div>
</template>

<script>
import newsInfo from '@/utils/newsInfo'
export default {
  name: 'Message',
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      loadText: '加载中...',
      loading: false,
      imgs: []
    }
  },
  mounted() {
    this.getNewsInfo()
    if (newsInfo.length <= 10) {
      this.loading = true
    }
  },
  methods: {
    // 获取 新闻数据
    getNewsInfo() {
      this.imgs.push(
        ...newsInfo.slice(
          this.pageSize * (this.currentPage - 1),
          this.pageSize * this.currentPage
        )
      )
    },

    more() {
      // 加载更多
      this.currentPage += 1
      // 数据全部加载后，隐藏‘加载更多’
      if (this.pageSize * this.currentPage >= newsInfo.length) {
        this.loading = true
      }
      this.getNewsInfo()
    },
    newsIn(index) {
      this.$router.push({ path: '/details', query: { index: index } })
    }
  }
}
</script>

<style lang="scss" scoped>
.servicer-box {
  padding: 0 0.3rem;
  padding-bottom: 1.03rem;
  margin: auto;
}

.img-box {
  background: #f4f4f4;
  display: flex;
  align-items: center;
  margin-bottom: 0.18rem;
  margin-top: 0.2rem;
}
.img {
  width: 2.9rem;
  height: 1.9rem;
}
.text-box {
  padding: 0 0.2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 1.5rem;
}
.text {
  font-size: 0.28rem;
  font-weight: 400;
  color: #555555;
  line-height: 0.39rem;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
.text2 {
  font-size: 0.24rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 0.35rem;
}
.more {
  width: 2.99rem;
  height: 0.79rem;
  background: #eee;
  color: #666666;
  text-align: center;
  font-size: 0.28rem;
  line-height: 0.79rem;
  margin: 0.5rem auto 0;
}
.loading {
  text-align: center;
  padding: 0.2rem;
  font-size: 0.26rem;
}
</style>
