<template>
  <div id="navbar">
    <!-- 导航栏 -->
    <!-- <Navigation /> -->
    <!-- 内容区域 -->
    <Content />
    <Xinwen />
    <!--底部-->
    <Foot />
  </div>
</template>

<script>
// import Navigation from '@/components/Navigation.vue'
import Foot from '@/components/Foot.vue'
import Xinwen from './components/Xinwen.vue'
import Content from './components/Content.vue'
export default {
  name: 'News',
  components: {
    // Navigation,
    Foot,
    Content,
    Xinwen
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped></style>
