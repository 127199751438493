<template>
  <div>
    <div class="jiejue-box"><span class="jj-text">新闻资讯</span></div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.jiejue-box {
  height: 4.2rem;
  margin: auto;
  background: url(../../../assets/images/zx.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  position: relative;
}
.jiejue-box::after {
  content: '';
  display: block;
  width: 100%;
  height: 4.2rem;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.28);
}
.jj-text {
  font-size: 0.6rem;
  font-weight: bold;
  color: #ffffff;
  line-height: 4.2rem;
  position: relative;
  z-index: 10;
}
</style>
